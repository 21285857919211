import { useOutletContext } from "react-router-dom";
import ProductTable from "./table/ProductTable";
import { Dispatch, SetStateAction } from "react";
import useToolkit from "hooks/useToolkit";

interface ContextType {
  createProductOpen?: boolean;
  setCreateProductOpen: Dispatch<SetStateAction<boolean>>;
  toggleTermsModal: () => void;
}

const Products = () => {
  const { businessProfile } = useToolkit();
  const { setCreateProductOpen, toggleTermsModal } = useOutletContext<ContextType>()
  const isVendor = businessProfile?.seller === true;

  return (
    <div>

      <div className="flex items-center justify-between pb-12 mb-12 border-b border-gray-100">
        <div className="page-title !text-3xl font-ojah font-bold border-gray-100">
          Products
        </div>
        <div>
          <button
            onClick={() => isVendor ? setCreateProductOpen(true) : toggleTermsModal()}
            className="btn btn-block btn-lg btn-shiga-black">
            Add a Product
          </button>
        </div>
      </div>

      <ProductTable
        setCreateProductOpen={setCreateProductOpen}
      />
    </div>
  );
};

export interface ProductType {
  id: string | number;
  name: string;
  price: number | string;
  currency: string;
  totalItem: number;
  itemSold: number;
  status: string;
  dateCreated: string;
  description: string;
  category: string;
}

export const products = {
  data: {
    meta: {
      size: 5,
      totalItems: 5,
      nextPage: 1,
      previousPage: 0,
    },
    payload: [
      {
        id: 1,
        name: "Burger King’s Hamburger",
        price: 4.99,
        currency: "USD",
        totalItem: 100,
        itemSold: 30,
        status: "Outgoing",
        dateCreated: "2024-07-10",
        description:
          "Burger King's hamburger: Flame-grilled beef patty on a soft bun with fresh toppings.",
        category: "Oil and Spices",
      },
      {
        id: 2,
        name: "Burger King’s Sandwich",
        price: "300,000",
        currency: "NGN",
        totalItem: 60,
        itemSold: 0,
        status: "Outgoing",
        dateCreated: "2024-07-11",
        description:
          "Burger King's hamburger: Flame-grilled beef patty on a soft bun with fresh toppings.",
        category: "Oil and Spices",
      },
      {
        id: 3,
        name: "Burger King’s Snack",
        price: 4.79,
        currency: "USD",
        totalItem: 200,
        itemSold: 120,
        status: "Outgoing",
        dateCreated: "2024-07-12",
        description:
          "Burger King's hamburger: Flame-grilled beef patty on a soft bun with fresh toppings.",
        category: "Oil and Spices",
      },
      {
        id: 4,
        name: "Burger King’s",
        price: 5.29,
        currency: "USD",
        totalItem: 80,
        itemSold: 50,
        status: "Outgoing",
        dateCreated: "2024-07-13",
        description:
          "Burger King's hamburger: Flame-grilled beef patty on a soft bun with fresh toppings.",
        category: "Oil and Spices",
      },
      {
        id: 5,
        name: "Combo Burger King’s",
        price: 4.99,
        currency: "USD",
        totalItem: 100,
        itemSold: 30,
        status: "Outgoing",
        dateCreated: "2024-07-10",
        description:
          "Burger King's hamburger: Flame-grilled beef patty on a soft bun with fresh toppings.",
        category: "Oil and Spices",
      },
    ],
  },
};

export default Products;
