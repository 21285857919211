import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Table from "components/common/Table";
import Pagination from "components/common/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as IconEdit } from "assets/images/icons/Edit2.svg";
import { ReactComponent as IconDelete } from "assets/images/icons/Delete.svg";
import IconEmptyProducts from "assets/images/icons/empty-products.svg";
import TableDropdown from "components/common/TableDropdown";
import useToolkit from "hooks/useToolkit";
import processParams from 'utils/processParams';
import useUrlQueryParams from "utils/useUrlQueryParams";
import { getProductDetails, getProducts, openProductEditModal } from "store/ecom/action";
import { RootState } from "store/store";
import Maybe from "components/common/Maybe";
import { TableContent } from "components/common/TableContent";
import EmptyUI from "components/common/EmptyUi";
import SearchBox from "components/common/SearchBox";

type Props = {
  setCreateProductOpen: Dispatch<SetStateAction<boolean>>;
}

const ProductTable = ({ setCreateProductOpen }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { gotoUrlQuery, dispatch, useSelector, formatCurrency } = useToolkit();

  const { urlQueryParams } = useUrlQueryParams();
  const { getProductsData: allProducts, getProductsLoading: isFetching } = useSelector((state: RootState) => state.ecom)
  const [listParams, setListParams] = useState({
    "page": 1,
    "limit": 9,
  });


  const queryParams = new URLSearchParams(location.search);
  const urlParams = Object.fromEntries(queryParams.entries());
  const statusQueries = ['Approved', 'Pending', 'Rejected']

  const statusFilterActive = urlParams?.status?.length > 0;
  const emptyProducts = isFetching === false && allProducts?.data?.data?.length === 0;

  // eslint-disable-next-line
  const handleOnClick = (productId: any) => {
    sessionStorage.setItem("view-product", productId || "");
    navigate(`/ojah/products/${productId}`);
  };

  const handleEdit = (product: any) => {
    dispatch(getProductDetails(product?.id));
    dispatch(openProductEditModal(product));
    setCreateProductOpen(true);
  }

  const fetchProducts = () => {
    let params = { ...listParams };

    params = processParams(urlQueryParams, params, "page", "number", 1, "page");
    params = processParams(urlQueryParams, params, "limit", "number", 9, "limit");
    params = processParams(urlQueryParams, params, "status", "string", "", "status");
    params = processParams(urlQueryParams, params, "search", "string", "", "search");

    setListParams(params);
    dispatch(getProducts(params));
  }

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line
  }, [location]);


  return (
    <div>

      <Maybe condition={!isFetching}>
        <div className="flex justify-between items-center">
          <div className="w-full lg:w-2/4 flex items-center space-x-5">
            {statusQueries.map((item: string) => {
              const urlFilterValue = urlParams?.status?.toLowerCase();
              const filterValue = item.toUpperCase();

              const isActive = item === 'Approved' ? (!urlFilterValue || urlFilterValue?.toLowerCase() === 'approved')
                : urlParams?.status?.toLowerCase() === item.toLowerCase();

              return (
                <button
                  key={item}
                  onClick={() => gotoUrlQuery({ status: filterValue })}
                  className={`active:scale-90 transition-transform ${isActive ? 'bg-shiga-dark-100 py-2 px-3.5 rounded-full text-white' : 'text-shiga-gray-100'}`}>
                  {item}
                </button>
              )
            })}
          </div>

          <div className=" basis-2/5 flex items-center justify-between">
            <div className="mr-6">
              <Pagination
                limit={9}
                meta={allProducts?.data?.meta}
              />
            </div>

            <div className="lg:pb-[26px]">
              {/* <Search
                value={searchQuery}
                placeholder="Search products"
                onSearchChange={(e: any) => setSearchQuery(e.target.value)}
              /> */}

              <SearchBox
                name='search'
                extraUrlParams='page=1'
                placeholder="Search products"
              />
            </div>
          </div>

        </div>
      </Maybe>

      <div className="mt-6">
        <Table tableClass="pb-24">

          <Maybe condition={(isFetching === false && !allProducts?.data?.data) || (allProducts?.data?.data?.length > 0)}>
            <Table.Head className="text-uppercase">
              <th>Name</th>
              <th>Price</th>
              <th>Status</th>
              <th>Sold</th>
              <th></th>
            </Table.Head>
          </Maybe>

          <Table.Body>

            <TableContent
              loading={isFetching}
              data={allProducts?.data?.data}
              loadingText="Fetching Products"
              total={allProducts?.data?.data?.length}
              colspan={5}
              emptyUI={
                <EmptyUI
                  icon={IconEmptyProducts}
                  header={statusFilterActive && emptyProducts ? "No products found" : "Upload your first product"}
                  subheader={statusFilterActive && emptyProducts ? "" : "You have no product listed yet. Why not create one?"}
                />
              }
            />

            {isFetching === false && allProducts?.data?.data?.map((product: any, index: number) => {

              const isApproved = product?.approvals === 'APPROVED'
              const isPending = product?.approvals === 'PENDING'
              const isRejected = product?.approvals === 'REJECTED'

              const isFirstItem = index === 0;

              return (

                <tr key={index} className="pt-20">

                  <td>
                    <div className="flex items-center">
                      <div className="mr-4 rounded-xl">
                        <ProductImgRenderer data={product?.photos} />
                      </div>

                      <div className="font-medium">
                        {product?.productName}
                      </div>
                    </div>
                  </td>

                  <td>
                    ₦{formatCurrency(product?.price)}
                  </td>

                  <td>
                    <p
                      className={`border border-shiga-gray-75 rounded-md py-1 px-[5px] flexed w-max text-[12px] capitalize font-inter font-medium
                    ${isApproved ? '' : isPending ? '' : isRejected ? 'border-shiga-gray-50 text-shiga-gray-200' : ''}`}>
                      {product?.approval}
                    </p>
                  </td>

                  <td>
                    <span className="font-medium">{product?.sold}</span>
                    <span className="font-light">
                      /{product?.quantity}
                    </span>
                  </td>

                  {/* <td>
                    {moment(product?.createdAt).format('MMM D, YYYY [at] h:mma')}
                  </td> */}

                  <td>
                    <div className='hover:bg-gray-200 py-1 px-pxs rounded-md'>
                      <TableDropdown
                        allowClick={true}
                        onRowClick={() => { }}
                        bottomPosition={isFirstItem}
                        options={[
                          {
                            permission: true,
                            title: 'Edit Product',
                            Icon: IconEdit,
                            onClick: () => handleEdit(product),
                          },
                          {
                            permission: true,
                            Icon: IconDelete,
                            onClick: () => { },
                            title: 'Delete Product',
                            iconClass: 'text-shiga-red-50',
                            titleClass: 'text-shiga-red-50',
                          },
                        ]}
                      />
                    </div>
                  </td>

                </tr>
              )
            })}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

const ProductImgRenderer = ({ data }: any) => {

  return (
    <div className="h-[50px] w-[50px] flex flex-wrap justify-between !rounded-xl overflow-hidden">
      {data?.map((item: any, idx: number) => {

        const isFirstItem = idx === 0;
        const isSecondItem = idx === 1;
        const isThirdItem = idx === 2;
        // const isFourthhItem = idx === 3;


        return (
          <div
            key={idx}
            className={`w-[46%] h-[50%] 
          ${(isFirstItem || isSecondItem) ? 'mb-[3px]' : (isFirstItem || isThirdItem) ? 'mrs-[3px]' : ''}`}>

            <img
              alt="product"
              src={item?.url}
              className="object-cover"
            />
          </div>
        )
      })}

    </div>
  )
}

export default ProductTable;
