import { RootState } from "store/store";
import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";
import { toast } from "react-toastify";

interface InitialState {
    registerVendorLoading: boolean;
    registerVendorData: any;

    productCategoriesLoading: boolean;
    productCategoriesData: any;

    createProductLoading: boolean;
    createProductData: any;

    productDetailsLoading: boolean;
    productDetailsData: any;

    getProductsLoading: boolean;
    getProductsData: any;

    updateProductLoading: boolean;
    updateProductData: any;

    activeProduct: any;
    isEditingProduct: boolean;
}

const initialState: InitialState = {
    registerVendorLoading: false,
    registerVendorData: null,

    productCategoriesLoading: false,
    productCategoriesData: null,

    productDetailsLoading: false,
    productDetailsData: null,

    createProductLoading: false,
    createProductData: null,

    updateProductLoading: false,
    updateProductData: null,

    getProductsLoading: false,
    getProductsData: null,

    activeProduct: null,
    isEditingProduct: false,
}


export default function reducer(state = initialState, { type, payload }: any): InitialState {
    switch (type) {

        case actions.CLOSE_PRODUCT_EDIT_MODAL:
            return {
                ...state,
                isEditingProduct: false,
                // activeProduct: null,
            }

        case actions.OPEN_PRODUCT_EDIT_MODAL:
            return {
                ...state,
                isEditingProduct: true,
                // activeProduct: payload,
            }

        case actions.EDIT_PRODUCT_DETAILS_START:
            return {
                ...state,
                updateProductLoading: true,
                updateProductData: null,
            }

        case actions.EDIT_PRODUCT_DETAILS_DONE:
            toast('Product updated successfully')
            return {
                ...state,
                updateProductLoading: false,
                updateProductData: payload,
            }

        case actions.EDIT_PRODUCT_DETAILS_FAILED:
            errorReducer(payload)
            return {
                ...state,
                updateProductLoading: false,
                updateProductData: payload,
            }

        case actions.GET_PRODUCT_DETAILS_START:
            return {
                ...state,
                productDetailsLoading: true,
                productDetailsData: null,
                activeProduct: null,
            }

        case actions.GET_PRODUCT_DETAILS_DONE:
            return {
                ...state,
                productDetailsLoading: false,
                productDetailsData: payload?.data,
                activeProduct: payload?.data,
            }

        case actions.GET_PRODUCT_DETAILS_FAILED:
            errorReducer(payload)
            return {
                ...state,
                productDetailsLoading: false,
                productDetailsData: payload,
                activeProduct: null,
            }

        case actions.GET_PRODUCTS_START:
            return {
                ...state,
                getProductsLoading: true,
                getProductsData: null
            }

        case actions.GET_PRODUCTS_DONE:
            return {
                ...state,
                getProductsLoading: false,
                getProductsData: payload,
            }

        case actions.GET_PRODUCTS_FAILED:
            errorReducer(payload)
            return {
                ...state,
                getProductsLoading: false,
                getProductsData: payload,
            }

        case actions.CREATE_PRODUCT_START:
            return {
                ...state,
                createProductLoading: true,
                createProductData: null
            }

        case actions.CREATE_PRODUCT_DONE:
            toast(payload.message)
            return {
                ...state,
                createProductLoading: false,
                createProductData: payload,
            }

        case actions.CREATE_PRODUCT_FAILED:
            errorReducer(payload)
            return {
                ...state,
                createProductLoading: false,
                createProductData: payload,
            }

        case actions.GET_PRODUCT_CATEGORIES_START:
            return {
                ...state,
                productCategoriesLoading: true,
                productCategoriesData: null
            }

        case actions.GET_PRODUCT_CATEGORIES_DONE:
            return {
                ...state,
                productCategoriesLoading: false,
                productCategoriesData: payload,
            }

        case actions.GET_PRODUCT_CATEGORIES_FAILED:
            errorReducer(payload)
            return {
                ...state,
                productCategoriesLoading: false,
                productCategoriesData: payload,
            }

        case actions.RESET_VENDOR_PARAMS:
            return {
                ...state,
                registerVendorLoading: false,
                registerVendorData: null,
                createProductData: null,
                productCategoriesData: null,
                productDetailsData: null,
            }

        case actions.REGISTER_VENDOR_START:
            return {
                ...state,
                registerVendorLoading: true,
                registerVendorData: null
            }

        case actions.REGISTER_VENDOR_DONE:
            return {
                ...state,
                registerVendorLoading: false,
                registerVendorData: payload,
            }

        case actions.REGISTER_VENDOR_FAILED:
            errorReducer(payload)
            return {
                ...state,
                registerVendorLoading: false,
                registerVendorData: payload,
            }

        default:
            return state;
    }
}


export const getRefundsStore = (state: RootState) => state.refunds;