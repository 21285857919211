import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import EcommerceNav from "./EcommerceNav";
import TermsAgreementModal from "components/updates/ecommerce/layout/TermsAgreementModal";
import ContextProvider from "components/updates/ecommerce/context/ContextProvider";
import InlineEcomLayout from "components/updates/ecommerce/layout/InlineEcomLayout";

export const OJAH_PATH = "/ojah";

const Index = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [termsModalShown, setTermsModalShown] = useState(false);
  const [createProductOpen, setCreateProductOpen] = useState(false);

  const toggleTermsModal = () => setTermsModalShown(!termsModalShown);

  useEffect(() => {
    if (pathname === `${OJAH_PATH}`) {
      navigate(`${OJAH_PATH}/products`);
    }
  }, [navigate, pathname]);

  return (
    <ContextProvider
      setSessionModalOpen={setCreateProductOpen}
    >

      <div className="flex justify-start items-stretch">
        <EcommerceNav />

        <div className="flex-grow px-20 py-11">
          <Outlet
            context={{
              createProductOpen,
              setCreateProductOpen,
              toggleTermsModal,
            }}
          />
        </div>

        <TermsAgreementModal
          isOpen={termsModalShown}
          setIsOpen={setTermsModalShown}
          setCreateProductOpen={setCreateProductOpen}
        />
      </div>

      <InlineEcomLayout
        sessionModalOpen={createProductOpen}
        setSessionModalOpen={setCreateProductOpen}
      />

    </ContextProvider>
  );
};

export default Index;
