import ObjectToQueryString from "utils/ObjectToQueryString";
import * as actions from "./actionTypes";
import * as config from "data/config";


export function closeProductEditModal() {
    return {
        type: actions.CLOSE_PRODUCT_EDIT_MODAL,
    }
}

export function openProductEditModal(payload: any) {
    return {
        type: actions.OPEN_PRODUCT_EDIT_MODAL,
        payload,
    }
}

export function selectActiveProduct(payload: any) {
    return {
        type: actions.SELECT_ACTIVE_PRODUCT,
        payload,
    }
}

export function deleteProduct(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiDeleteOjahProduct + ObjectToQueryString(payload),
            method: "delete",
            data: {},
            onStart: actions.DELETE_PRODUCT_START,
            onSuccess: actions.DELETE_PRODUCT_DONE,
            onError: actions.DELETE_PRODUCT_FAILED
        }
    }
}

export function editProduct(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiEditOjahProduct + ObjectToQueryString(payload),
            method: "patch",
            data: {},
            onStart: actions.EDIT_PRODUCT_START,
            onSuccess: actions.EDIT_PRODUCT_DONE,
            onError: actions.EDIT_PRODUCT_FAILED
        }
    }
}

export function edtProductDetails(id: any, payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiGetProductDetails + id,
            method: "put",
            data: payload,
            onStart: actions.EDIT_PRODUCT_DETAILS_START,
            onSuccess: actions.EDIT_PRODUCT_DETAILS_DONE,
            onError: actions.EDIT_PRODUCT_DETAILS_FAILED
        }
    }
}

export function getProductDetails(id: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiGetProductDetails + id,
            method: "get",
            data: {},
            onStart: actions.GET_PRODUCT_DETAILS_START,
            onSuccess: actions.GET_PRODUCT_DETAILS_DONE,
            onError: actions.GET_PRODUCT_DETAILS_FAILED
        }
    }
}

export function getProducts(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiGetProducts + ObjectToQueryString(payload),
            method: "get",
            data: {},
            onStart: actions.GET_PRODUCTS_START,
            onSuccess: actions.GET_PRODUCTS_DONE,
            onError: actions.GET_PRODUCTS_FAILED
        }
    }
}

export function createProduct(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiCreateProduct,
            method: "post",
            data: payload,
            onStart: actions.CREATE_PRODUCT_START,
            onSuccess: actions.CREATE_PRODUCT_DONE,
            onError: actions.CREATE_PRODUCT_FAILED
        }
    }
}

export function getProductCategories() {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiGetProductCategories,
            method: "get",
            data: {},
            onStart: actions.GET_PRODUCT_CATEGORIES_START,
            onSuccess: actions.GET_PRODUCT_CATEGORIES_DONE,
            onError: actions.GET_PRODUCT_CATEGORIES_FAILED
        }
    }
}

export function registerOjahVendor(id: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiAcceptOjahTerms,
            method: "post",
            data: {},
            onStart: actions.REGISTER_VENDOR_START,
            onSuccess: actions.REGISTER_VENDOR_DONE,
            onError: actions.REGISTER_VENDOR_FAILED
        }
    }
}

export function resetVendorParams() {
    return {
        type: actions.RESET_VENDOR_PARAMS,
    }
}