
export const OPEN_PRODUCT_EDIT_MODAL = "OPEN_PRODUCT_EDIT_MODAL";
export const CLOSE_PRODUCT_EDIT_MODAL = "CLOSE_PRODUCT_EDIT_MODAL";

export const SELECT_ACTIVE_PRODUCT = "SELECT_ACTIVE_PRODUCT";
export const RESET_VENDOR_PARAMS = "RESET_VENDOR_PARAMS";

export const EDIT_PRODUCT_START = "EDIT_PRODUCT_START";
export const EDIT_PRODUCT_DONE = "EDIT_PRODUCT_DONE";
export const EDIT_PRODUCT_FAILED = "EDIT_PRODUCT_FAILED";

export const DELETE_PRODUCT_START = "DELETE_PRODUCT_START";
export const DELETE_PRODUCT_DONE = "DELETE_PRODUCT_DONE";
export const DELETE_PRODUCT_FAILED = "DELETE_PRODUCT_FAILED";

export const EDIT_PRODUCT_DETAILS_START = "EDIT_PRODUCT_DETAILS_START";
export const EDIT_PRODUCT_DETAILS_DONE = "EDIT_PRODUCT_DETAILS_DONE";
export const EDIT_PRODUCT_DETAILS_FAILED = "EDIT_PRODUCT_DETAILS_FAILED";

export const GET_PRODUCT_DETAILS_START = "GET_PRODUCT_DETAILS_START";
export const GET_PRODUCT_DETAILS_DONE = "GET_PRODUCT_DETAILS_DONE";
export const GET_PRODUCT_DETAILS_FAILED = "GET_PRODUCT_DETAILS_FAILED";

export const GET_PRODUCTS_START = "GET_PRODUCTS_START";
export const GET_PRODUCTS_DONE = "GET_PRODUCTS_DONE";
export const GET_PRODUCTS_FAILED = "GET_PRODUCTS_FAILED";

export const CREATE_PRODUCT_START = "CREATE_PRODUCT_START";
export const CREATE_PRODUCT_DONE = "CREATE_PRODUCT_DONE";
export const CREATE_PRODUCT_FAILED = "CREATE_PRODUCT_FAILED";

export const GET_PRODUCT_CATEGORIES_START = "GET_PRODUCT_CATEGORIES_START";
export const GET_PRODUCT_CATEGORIES_DONE = "GET_PRODUCT_CATEGORIES_DONE";
export const GET_PRODUCT_CATEGORIES_FAILED = "GET_PRODUCT_CATEGORIES_FAILED";

export const REGISTER_VENDOR_START = "REGISTER_VENDOR_START";
export const REGISTER_VENDOR_DONE = "REGISTER_VENDOR_DONE";
export const REGISTER_VENDOR_FAILED = "REGISTER_VENDOR_FAILED";